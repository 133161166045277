.ticket-page {
    form {
        margin: 1em;
    }
    .nowrap {
        text-wrap: 'nowrap'
    }

    .buttonPanel {
        padding: 11px 5px 0px 5px;
    }

    textarea,
    input[type='text']
    {
        width: 280px;
    }

    td {
        padding: 11px 5px;
    }

    textarea,
    input[type='text'],
    select {
        border: 1px solid #ddd;
        padding: 3px 5px;
        border-radius: 3px;
    }

    select  {
        box-sizing: unset;
    }

    .form-table {
        background-color:#FFFFFF;
        width: 100%;
    }

    .label-text{
        color:#000000;
        font-family:Arial;
        font-size:13px;
        width: 60px;
    }

    .validation-error{
        border-left: 1px solid #ff6448 !important;
    }

    .hleft{
        text-align:left;
    }

    input[type=file]::-webkit-file-upload-button{
        cursor:pointer;
    }

    .wtcsepcode{
        margin:0px 15px;
        color:#aaa;
        float:left;
    }

    .wtccloudattach{
        float:left;
        color:#00a3fe!important;
        cursor:pointer;
        text-decoration: none !important;
    }

    .wtccloudattach:hover{
        text-decoration: none !important;
    }

    .wtcuploadinput{
        cursor:pointer;
        float:left;
        width:62px;
        margin-top:-20px;
        opacity:0;
        clear:both;
    }

    .x-button {
        margin-left: 2em;
    }

    .wtcuploadfile{
        float:left;
        color: #00a3fe;
        cursor: default;

        &.canAdd {
            cursor: pointer;
        }
    }

    .filenamecls{
        margin-right:15px;
        float:left;
        margin-top:5px;
    }

    .clboth{
        clear:both;
    }

    #zsFileBrowseAttachments{
        clear:both;
        margin:5px 0px 10px;
    }

    .label-text{
        vertical-align:top;
    }

    .flavor {
        color: black;
        font-size: .7rem;
        float: left;
        margin-left: 14px;
    }

    .alert {
        font-size: .8em;
        color: red;
        padding: 0;
        margin: 0;
    }

}
